.cover-container{
  padding: 0% 4% 0% 4%;
}

.padding{
  padding: 4%;
}

.cover-image{
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  max-width: 180px;
}

.margin-top{
	margin-top: 5%;
}

.contact-icon{
	width: 100%;
}

.contact-icon:hover{
	opacity: 0.8;
}

.contact-container{
	max-width: 150px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 4%;
}

.contact-col{
	padding: 5px;
}

.photo-contact-container{
	margin-top: 75px;
}