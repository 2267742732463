.container-miscellaneous-padding{
	margin-top: 60px;
	padding: 0% 0% 5% 0%;
}

.container-miscellaneous-padding h4{
	padding-left: 5%;
}

.miscellaneous-opening-text{
	padding-left: 5%;
}

.small-margin-top{
	margin-top: 3%;
}