.container-teaching-experience-padding{
	margin-top: 60px;
	padding: 0% 0% 5% 0%;
}

.container-teaching-experience-padding h4{
	padding-left: 5%;
}

.margin-top-teaching-experience{
	margin-top: 3%;
}

.project-image-container table{
	width: 100%;
	height: 100%;
}

.project-image-container td{
	vertical-align: middle;
	text-align: center;
}

.project-image{
	width: 100%;
	max-width: 125px;
}

.project-image:hover{
	opacity: 0.9;
}

.embed-container { 
	position: relative; 
	padding-bottom: 56.25%; 
	height: 0; 
	overflow: hidden; 
	max-width: 100%; 
}

.embed-container iframe, .embed-container object, .embed-container embed { 
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}